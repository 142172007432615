<template>
  <v-card>
    <v-card-title>
      <slot name="addNewRowButton">Title</slot>
      <v-spacer></v-spacer>
      <v-text-field
        v-if="isSearchBoxVisible"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="getHeaderList"
      :items="dataTableList"
      item-key="id"
      class="elevation-1"
      :search="search"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="totalAvailableItemsInDB"
      :hide-default-footer="itemsPerPage <= 0"
      :footer-props="{
         itemsPerPageOptions: this.itemsPerPageOptionsArray,
      }">
      <template slot="items" slot-scope="props">
        <td :key="index" v-for="(header, index) in headers">
          {{ props.item[header.value] }}
        </td>
      </template>
      <template v-slot:item.createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:item.updatedDate="{ item }">
        {{ item.updatedDate | formatDate }}
      </template>
      <template v-slot:item.userActive="{ item }">
        <v-icon v-if="item.userActive" color="green" medium
          >mdi-thumb-up</v-icon
        >
        <v-icon v-else color="error" medium>mdi-thumb-down</v-icon>
      </template>
      <template v-slot:item.userTypeEnum="{ item }">
        {{ item.userTypeEnum | userTypeFilter }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              :color="appUserCsvExportFileIconColor"
              v-if="tableType === APP_DATATABLE_TYPE_USER_LIST"
              medium
              @click="downloadAsCsv(item)"
              >mdi-file-excel</v-icon
            >
            <v-icon
              v-on="on"
              color="green"
              v-if="
                tableType === APP_DATATABLE_TYPE_ACTION_EDIT_DELETE ||
                tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST
              "
              medium
              @click="editItem(item)"
              >mdi-pencil-circle</v-icon
            >
          </template>
          <span v-if="tableType === APP_DATATABLE_TYPE_USER_LIST">{{
            $t("datatable.actionButtons.csvDownload")
          }}</span>
          <span
            v-if="
              tableType === APP_DATATABLE_TYPE_ACTION_EDIT_DELETE ||
              tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST
            "
            >{{ $t("datatable.actionButtons.editRow") }}</span
          >
        </v-tooltip>
        <span class="ml-3 mr-3">|</span>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              color="primary"
              medium
              v-if="tableType === APP_DATATABLE_TYPE_USER_LIST &&
                    item.queryStringType === APP_ADMIN_QUERY_TYPE_SQL"
              @click="viewQueryDetails(item)"
              >mdi-table-large</v-icon
            >
             <v-icon
              v-on="on"
              color="blue"
              medium
              v-if="
                tableType === APP_DATATABLE_TYPE_USER_LIST &&
                item.queryStringType === APP_ADMIN_QUERY_TYPE_JSON"
              @click.stop="onClickOfRestApi(item)"
              >mdi-cloud-braces</v-icon
            >
            <v-icon
              v-on="on"
              color="red"
              medium
              v-if="
                tableType === APP_DATATABLE_TYPE_ACTION_EDIT_DELETE ||
                tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST
              "
              @click.stop="openDeleteConfirmationDialog(item)"
              >mdi-delete-circle</v-icon
            >
          </template>
          <span v-if="tableType === APP_DATATABLE_TYPE_USER_LIST">{{
            $t("datatable.actionButtons.htmlView")
          }}</span>
          <span
            v-if="
              tableType === APP_DATATABLE_TYPE_ACTION_EDIT_DELETE ||
              tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST
            "
            >{{ $t("datatable.actionButtons.deleteRow") }}</span
          >
        </v-tooltip>
        <span
          class="ml-3 mr-3"
          v-if="tableType !== APP_DATATABLE_TYPE_ACTION_EDIT_DELETE"
          >|</span
        >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              color="primary"
              v-if="tableType === APP_DATATABLE_TYPE_USER_LIST"
              medium
              @click="onClickOfJsonViewer(item)"
              >mdi-code-json</v-icon
            >
            <v-icon
              v-on="on"
              color="blue"
              medium
              v-if="
                tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST &&
                item.queryStringType === APP_ADMIN_QUERY_TYPE_SQL
              "
              @click.stop="previewQueryDetails(item)"
              >mdi-database</v-icon
            >
            <v-icon
              v-on="on"
              color="blue"
              medium
              v-if="
                tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST &&
                item.queryStringType === APP_ADMIN_QUERY_TYPE_JSON
              "
              @click.stop="onClickOfRestApi(item)"
              >mdi-cloud-braces</v-icon
            >
          </template>
          <span v-if="tableType === APP_DATATABLE_TYPE_USER_LIST">
            {{ $t("datatable.actionButtons.jsonView") }}</span
          >
          <span v-if="tableType === APP_DATATABLE_TYPE_ADMIN_QUERY_LIST">
            {{ $t("datatable.actionButtons.preview") }}</span
          >
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteConfirmationDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">{{
          $t("admin.userManagement.deleteConfirmation")
        }}</v-card-title>
        <v-card-text class="mt-4">
          {{ $t("admin.userManagement.deleteConfirmationMsg") }}</v-card-text
        >
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click.stop="deleteConfirmationDialog = false"
            >{{ $t("app.button.no") }}</v-btn
          >
          <v-btn color="primary" outlined text @click.stop="deleteItem()">{{
            $t("app.button.yesDelete")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" width="500">
      <slot
        name="editDialogContentSlot"
        v-bind:selectedItem="selectedItem"
        v-bind:closeEditDialog="closeEditDialog"
      ></slot>
    </v-dialog>
    <slot
      name="editUserDialogContentSlot"
      v-bind:editDialog="editDialog"
      v-bind:selectedItem="selectedItem"
      v-bind:closeEditDialog="closeEditDialog"
    ></slot>
    <slot
      name="editDataQueryDialogContentSlot"
      v-bind:editDataQueryDialog="editDataQueryDialog"
      v-bind:dataQueryItem="dataQueryItem"
      v-bind:closeDataQueryDialog="closeDataQueryDialog"
    ></slot>
    <slot
      name="restApiDialogContentSlot"
      v-bind:restApiDialog="restApiDialog"
      v-bind:userActionType="userActionType"
      v-bind:dataQueryItem="dataQueryItem"
      v-bind:closeRestApiDialog="closeRestApiDialog"
    ></slot>
  </v-card>
</template>

<script>
import Constants from '@/constants';
import ColorVariable from '@/styles/Variables.scss';

export default {
  props: {
    tableType: {
      type: String,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    totalNumberOfItemsAvailable: {
      type: Number,
      required: false,
    },
    itemsPerPageProps: {
      type: Number,
      required: false,
    },
    itemsPerPageOptionsProps: {
      type: Array,
      required: false,
    },
    isSqlType: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      jsonViewerDialog: false,
      deleteConfirmationDialog: false,
      editDialog: false,
      editDataQueryDialog: false,
      restApiDialog: false,
      selectedItem: {},
      dataQueryItem: {},
      userActionType: '',
      options: {},
      itemsPerPage: 0,
      totalAvailableItemsInDB: -1,
      itemsPerPageOptionsArray: [10, 20, 30, 40, 50, -1],
      APP_DATATABLE_TYPE_USER_LIST: Constants.APP_DATATABLE_TYPE_USER_LIST,
      APP_DATATABLE_TYPE_ACTION_EDIT_DELETE:
        Constants.APP_DATATABLE_TYPE_ACTION_EDIT_DELETE,
      APP_DATATABLE_TYPE_ADMIN_QUERY_LIST:
        Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST,
      APP_ADMIN_QUERY_TYPE_SQL: Constants.APP_ADMIN_QUERY_TYPE_SQL,
      APP_ADMIN_QUERY_TYPE_JSON: Constants.APP_ADMIN_QUERY_TYPE_JSON,
      USER_LIST_ACTION_TYPE_CSV_DOWNLOAD: Constants.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD,
      USER_LIST_ACTION_TYPE_JSON_VIEW: Constants.USER_LIST_ACTION_TYPE_JSON_VIEW,
      APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW: Constants.APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW,
      APP_DATATABLE_TYPE_DATA_LIST: Constants.APP_DATATABLE_TYPE_DATA_LIST,
      appUserCsvExportFileIconColor: ColorVariable.appUserCsvExportFileIconColor,
      search: '',
      skipHeadersMap: {
        [Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST]: ['primaryKeyField', 'templateForUniqueRowDesc', 'disableSnapshot', 'queryStringType', 'ungerboeckApiBaseUrl', 'queryString', 'currentConnectionString', 'validFrom', 'validTo', 'availableRoles', 'status', 'createdBy'],
      },
    };
  },
  computed: {

    isSearchBoxVisible() {
      const disabledForTableTypeArray = [this.APP_DATATABLE_TYPE_DATA_LIST,
        this.APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW];
      console.log('disabledForTableTypeArray : ', disabledForTableTypeArray);
      console.log('this.tableType: ', this.tableType);
      if (disabledForTableTypeArray.indexOf(this.tableType) >= 0) {
        console.log('Exist : ');
        return this.isSqlType;
      }
      return true;
    },

    dataTableList() {
      return this.dataList;
    },

    // Fetch header list from datalist for dynamic list.
    getHeaderList() {
      if (this.dataTableList && this.dataTableList.length > 0) {
        const updatedHeaderList = Object.keys(this.dataTableList[0]).map(
          (item) => ({
            text:
              this.tableType
              === Constants.APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW
                ? item
                : this.$t(`datatable.tableHeaders.${item}`),
            value: item,
            align: this.checkIfHeaderExistInSkipHeaderMap(item) ? ' d-none' : 'start',
            sortable: true,
            filterable: true,
            groupable: false,
            divider: false,
            width: 150,
            // width: `string | number,
          }),
        );
        console.log('----------xxxxxxx 1111----------', this.tableType);
        if (updatedHeaderList && updatedHeaderList.length > 0) {
          if (
            this.tableType === this.APP_DATATABLE_TYPE_USER_LIST
            || this.tableType === this.APP_DATATABLE_TYPE_ACTION_EDIT_DELETE
            || this.tableType === this.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST
          ) {
            updatedHeaderList.push({
              text: this.$t('datatable.tableHeaders.Actions'),
              value: 'actions',
              sortable: false,
              width: 160,
            });
          }
          console.log(
            '----------xxxxxxx 2222----------',
            updatedHeaderList.filter((item) => item.value !== 'id'),
          );
          return updatedHeaderList.filter((item) => item.value !== 'id');
        }
      }
      return [];
    },
  },
  methods: {
    /**
     * Method to download content in CSV file.
     */
    downloadAsCsv(item) {
      if (item) {
        console.log('========== Datatable : downloadAsCsv =========');

        if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_JSON) {
          if (item.queryString) {
            if ((item.queryString).indexOf('/{') === -1) {
              this.$emit('DownloadAsCsvFileEmitter', item);
            } else {
              this.dataQueryItem = { ...item };
              this.restApiDialog = true;
              this.userActionType = this.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD;
            }
          }
        } else {
          this.$emit('DownloadAsCsvFileEmitter', item);
        }
      }
    },

    /**
     * method get called when user click on json viewer icon button.
     */
    onClickOfJsonViewer(item) {
      if (item) {
        console.log('------------json viewer on click ---------', item);
        if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_JSON) {
          if (item.queryString) {
            if ((item.queryString).indexOf('/{') === -1) {
              this.$emit('ViewDataQueryAsJsonFileEmitter', item);
            } else {
              this.dataQueryItem = { ...item };
              this.restApiDialog = true;
              this.userActionType = this.USER_LIST_ACTION_TYPE_JSON_VIEW;
            }
          }
        } else {
          this.$emit('ViewDataQueryAsJsonFileEmitter', item);
        }
      }
    },

    /**
     * This will redirect user to user detail page to view content in tabular form.
     */
    viewQueryDetails(item) {
      console.log('------ viewQueryDetails clicked --------', item);
      this.$router.push({
        name: 'UserQueryDetails',
        params: { dataQueryId: item.id, dataQueryName: item.displayName },
      });
    },

    /**
     * This will redirect user to admin query detail page to view content in tabular form.
     */
    previewQueryDetails(item) {
      console.log('------ previewQueryDetails clicked --------', item);
      this.$router.push({
        name: 'AdminQueryDetails',
        params: { dataQueryId: item.id, dataQueryName: item.displayName },
      });
    },

    /**
     * Edit the current row and navigate user to edit screen.
     */
    editItem(item) {
      console.log('------ Edit item ------- ', item);
      if (item) {
        console.log('------ Edit item non empty ------- ', item);
        if (this.tableType === Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST) {
          console.log('------ Table type admin query list ------- ', this.tableType);
          this.editDataQueryDialog = true;
          this.dataQueryItem = { ...item };
        } else {
          console.log('------ NOT: Table type admin query list ------- ', this.tableType);
          this.editDialog = true;
          this.selectedItem = { ...item };
        }
      }
    },

    /**
     * on click of rest api.
     */
    onClickOfRestApi(item) {
      console.log('------ onClickOfRestApi item ------- ', item);
      if (item) {
        if (item.queryString) {
          if ((item.queryString).indexOf('/{') === -1) {
            console.log('------ onClickOfRestApi : Hardcoded Url clicked --------', item);
            if (this.tableType === this.APP_DATATABLE_TYPE_USER_LIST) {
              this.$router.push({
                name: 'UserQueryDetails',
                params: {
                  restUrlParam: item.queryString,
                  dataQueryIdForApi: item.id,
                  dataQueryName: item.displayName,
                },
              });
            } else if (this.tableType === this.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST) {
              this.$router.push({
                name: 'AdminQueryDetails',
                params: {
                  restUrlParam: item.queryString,
                  dataQueryIdForApi: item.id,
                  dataQueryName: item.displayName,
                },
              });
            }
          } else {
            this.dataQueryItem = { ...item };
            this.restApiDialog = true;
            this.userActionType = '';
          }
        }
      }
    },

    /**
     * Delete the current row and navigate user to edit screen.
     */
    deleteItem() {
      console.log(
        '------ delete item from confirmation dialog ------- ',
        this.selectedItem, ' : ', this.tableType,
      );
      if (this.tableType === Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST) {
        if (this.dataQueryItem) {
          this.$emit(
            Constants.GHM_DATA_TABLE_DELETE_ITEM_EVENT,
            this.dataQueryItem.id,
          );
          this.dataQueryItem = {};
        }
      } else if (this.selectedItem) {
        this.$emit(
          Constants.GHM_DATA_TABLE_DELETE_ITEM_EVENT,
          this.selectedItem.id,
        );
        this.selectedItem = {};
      }

      this.deleteConfirmationDialog = false;
    },

    /**
     * Close edit dialog on click of close button
     */
    closeEditDialog() {
      console.log('============ Closed edit dialog called ===============');
      this.editDialog = false;
    },

    /**
     * Close Rest Api Dialog on click of close button
     */
    closeRestApiDialog() {
      console.log('============ Closed Rest Api dialog called ===============');
      this.restApiDialog = false;
    },

    /**
     * Close Data Query Dialog on click of close button
     */
    closeDataQueryDialog() {
      console.log('============ Closed Data Query dialog called ===============');
      this.editDataQueryDialog = false;
    },

    /**
     * confirmation dialog for delete.
     */
    openDeleteConfirmationDialog(item) {
      console.log('============ openDeleteConfirmationDialog 11===============', item);
      if (item) {
        console.log('============ openDeleteConfirmationDialog 22 ===============', this.tableType);
        if (this.tableType === Constants.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST) {
          this.dataQueryItem = item;
        } else {
          this.selectedItem = item;
        }
      }
      this.deleteConfirmationDialog = true;
    },

    /**
     * Hide header if its exist in skip header map for table type.
     */
    checkIfHeaderExistInSkipHeaderMap(headerName) {
      let isHeaderExist = false;
      if (headerName) {
        if (this.skipHeadersMap) {
          const skipArray = this.skipHeadersMap[this.APP_DATATABLE_TYPE_ADMIN_QUERY_LIST];
          if (skipArray && skipArray instanceof Array) {
            for (let i = 0; i < skipArray.length; i += 1) {
              if (headerName === skipArray[i]) {
                isHeaderExist = true;
                break;
              }
            }
          }
        }
      }
      return isHeaderExist;
    },

  },

  watch: {

    /**
     * Get totalNumberOfAvailable Items for server side pagination.
     */
    totalNumberOfItemsAvailable(newProp) {
      console.log('----- Watch totalNumberOfItemsAvailable----- ', newProp);
      this.totalAvailableItemsInDB = newProp;
      this.$emit('ItemsPerPageEmitter', this.itemsPerPage);
    },

    /**
     * Set local data variable when itemsPerPage changed.
     */
    itemsPerPageProps(newItemPerPageProp) {
      console.log('----- Watch itemsPerPageProps----- ', newItemPerPageProp);
      this.itemsPerPage = newItemPerPageProp;
    },

    /**
     * item per page array options
     */
    itemsPerPageOptionsProps(newItemPerPageOptions) {
      console.log('-------- itemsPerPageOptionsProps -------------', newItemPerPageOptions);
      this.itemsPerPageOptionsArray = newItemPerPageOptions;
    },

    /**
     * when user clicks on pagination input
     */
    options: {
      handler() {
        console.log('------------ >>> on change option emitter starting....');
        this.$emit('OnChangeOfOptionEmitter', this.options);
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
</style>
